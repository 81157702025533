import BrowserDetect from './browser-detect';

/*
ABOUT PAGE FRAME ANIMATION
*/
const aboutFrames = function(window, document, app, TimelineLite, Vivus, Back) {
  /*
  HEADER SCREEN + ABOUT SCREEN
  */

  const filterIt = (img) => {
    const src = img.src;
    const height = img.height;
    const width = img.width;
    const parent = img.parentNode;
    const id = `__${Math.round(Math.random()*1000)}`;
    const svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink= "http://www.w3.org/1999/xlink"
      height="${height}" width="${width}" class="team-item__image">
  		<defs>
  		 	<filter id="${id}" x="0" y="0">
       		<feColorMatrix type="saturate" values="0"/>
      	</filter>
      </defs>
  		<image xlink:href="${src}" x="0" y="0" height="${height}" width="${width}" filter="url(#${id})"/>
  	</svg>`;
    $(img).before(svg);
    $(img).remove();
    let interval;
    const color = $(parent).find('feColorMatrix');
    console.log(color.attr('values'));
    $(parent).hover(function() {
      let current = 0;
      if (interval)
        clearInterval(interval);
      interval = setInterval(() => {
        current = current + 10;
        color.attr('values', (current) / 100);
        if (current >= 100)
          clearInterval(interval);
      }, 1);
    }, function() {
      let current = 100;
      if (interval)
        clearInterval(interval);
      interval = setInterval(() => {
        current = current - 10;
        color.attr('values', (current) / 100);
        if (current <= 0)
          clearInterval(interval);
      }, 1);
    });
  }

  app.on('about-frame-1', ({
    header,
    splitedHeaderText,
    headerParts,
    aboutParts,
    content,
    splitedTitle,
    splited,
    svgWrapper
  }) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .1, "+=0");
        for (let i = 0; i < aboutParts.length; i++) {
          aboutParts[i].classList.add('animated');
        }
      }, 1200);
      setTimeout(() => {
        content.classList.add('animated');
        const titleTl = new TimelineLite();
        titleTl.staggerTo(splitedTitle, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .1, "+=0");
        // for (let i = 0; i < splitedParagraphs.length; i++) {
        //   let splited = splitedParagraphs[i].querySelectorAll('span');
        const paragraphTl = new TimelineLite();
        paragraphTl.staggerTo(splited, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .005, "+=0", () => {
          svgWrapper.classList.add('animated');
        });
        // }
      }, 2200)
    });
  });
  app.on('about-frame-2', ({
    video,
    parts,
    splited,
    helper,
    stick,
    serif,
    bg,
    svgId,
    svgWrapper
  }) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    setTimeout(() => {
      new Vivus(svgId, {
        duration: 1500,
        type: 'async',
        start: 'autostart'
      });
      svgWrapper.classList.add('animated');
      const tl = new TimelineLite();
      tl.staggerTo(splited, 2, {
        opacity: 1,
        ease: Back.easeOut
      }, .1, "+=0");
      bg.classList.add('animated');
      stick.classList.add('animated');
      serif.classList.add('animated');
    }, 1000);
    setTimeout(() => {
      helper.classList.add('animated');
    }, 2000);
  });
  app.on('facts-counters', ({
    counters,
    max
  }) => {
    let interval;
    let j = 0;
    let step = .4;
    if (app.width() > 1024) {
      interval = setInterval(function() {
        for (let i = 0; i < counters.length; i++) {
          counters[i].currentNumber += counters[i].needNumber / (max / step);
          counters[i].innerHTML = parseInt(counters[i].currentNumber);
        }
        j += step;
        if (j >= max) {
          clearInterval(interval);
          for (let i = 0; i < counters.length; i++) {
            counters[i].innerHTML = counters[i].needNumber;
          }
        }
      }, 1);
    } else {
      for (let i = 0; i < counters.length; i++) {
        counters[i].innerHTML = counters[i].needNumber;
      }
    }
  });

  app.on('about-frame-3', ({
    parts,
    max,
    counters,
    splited,
    facts
  }) => {
    for (var i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
      facts[i].classList.add('animated');
    }
    const tl = new TimelineLite();
    tl.staggerTo(splited, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .1, "+=0");
    app.fire('facts-counters', {
      counters: counters,
      max: max,
    });
  });
  app.on('about-frame-4', ({
    parts,
    form,
    button,
    splited
  }) => {
    for (var i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    const tl = new TimelineLite();
    tl.staggerTo(splited, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .05, "+=0");
    setInterval(() => {
      button.classList.add('animated');
    }, 1000);
  });
  app.on('about-frame-6', ({
    services,
    parts,
    splited,
    bg,
    items
  }) => {
    bg.classList.add('animated');
    const tl = new TimelineLite();
    let index = 0;
    let interval = setInterval(() => {
      items[index++].classList.add('animated');
      if (index === items.length)
        clearInterval(interval);
    }, 500);
    tl.staggerTo(splited, 2, {
      opacity: 1,
      ease: Back.easeOut
    }, .2, "+=0");
    setTimeout(() => {
      for (let i = 0; i < parts.length; i++) {
        parts[i].classList.add('animated');
      }
    }, 500)
  });

  (function() {
    const header = document.querySelector('.header--about');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.split-text span');
    const headerParts = header.querySelectorAll('.header__part');
    const about = document.querySelector('.about');
    const aboutParts = about.querySelectorAll('.about__part');
    const content = about.querySelector('.about__content');
    const splitedTitle = about.querySelectorAll('.about__title span, .about__subtitle span');
    const splited = about.querySelectorAll('.about__paragraph span,.about__excerpt span, .about__excerpt-description span');
    const svgWrapper = about.querySelector('.about__svg-wrapper');
    const aboutFrame1Handler = () => {
      app.fire('about-frame-1', {
        header: header,
        splitedHeaderText: splitedHeaderText,
        headerParts: headerParts,
        aboutParts: aboutParts,
        content: content,
        splitedTitle: splitedTitle,
        splited: splited,
        svgWrapper: svgWrapper
      });
    };
    // window.addEventListener('load', aboutFrame1Handler);
    aboutFrame1Handler();
  })();


  /*
  VIDEO SCREEN + ABOUT SCREEN
  */
  (function() {
    const video = document.querySelector('div.video--about');
    if (!video) return;
    const parts = video.querySelectorAll('.video__part');
    const splited = video.querySelectorAll('.split-text span');
    const helper = video.querySelector('.video__helper');
    const stick = video.querySelector('.video__stick');
    const serif = video.querySelector('.video__serif');
    const bg = video.querySelector('.video__bg');
    const svgWrapper = video.querySelector('.video__svg-wrapper');
    const svgId = 'video-svg';
    let timeout;
    const aboutFrame2Handler = () => {
      if ($(window).scrollTop() >= $(video).offset().top - 600) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('about-frame-2', {
              video: video,
              parts: parts,
              splited: splited,
              helper: helper,
              stick: stick,
              serif: serif,
              bg: bg,
              svgId: svgId,
              svgWrapper: svgWrapper
            });
          }, 100);
      }
    }
    window.addEventListener('load', aboutFrame2Handler);
    window.addEventListener('scroll', aboutFrame2Handler);
  })();


  /*
  PLAY VIDEO HANDLER
  */
  (function() {
    const videoButton = document.querySelector('.video__play-button');
    if (!videoButton) return;
    const video = document.querySelector('.video__video');
    video.volume = .2;
    videoButton.addEventListener('click', () => {
      video.classList.add('active');
      video.play();
      videoButton.classList.add('disabled');
    });
    video.addEventListener('ended', function() {
      videoButton.classList.remove('disabled');
      this.classList.remove('active');
    });
  })();

  /*
  FACTS COUNTERS
  FACTS FRAME
  */

  (function() {
    const fact = document.querySelector('.facts');
    if (!fact) return;
    const parts = fact.querySelectorAll('.facts__part');
    const facts = fact.querySelectorAll('.fact');
    const counters = fact.querySelectorAll('.fact__number');
    const splited = fact.querySelectorAll('.split-text span');
    const numbersArr = [];
    for (let i = 0; i < counters.length; i++) {
      let needNumber = counters[i].getAttribute('data-number');
      counters[i].needNumber = needNumber;
      counters[i].currentNumber = 0;
      numbersArr.push(needNumber);
    }
    const max = Math.max.apply(null, numbersArr);
    let timeout;
    const aboutFrame3Handler = () => {
      if ($(window).scrollTop() >= $(fact).offset().top - 600)
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('about-frame-3', {
              parts: parts,
              facts: facts,
              splited: splited,
              counters: counters,
              max: max
            });
          }, 100);
    };
    window.addEventListener('load', aboutFrame3Handler);
    window.addEventListener('scroll', aboutFrame3Handler);
  })();



  /*
  FORM FRAME
  */
  (function() {
    const form = document.querySelector('.about-form');
    if (!form) return;
    const parts = form.querySelectorAll('.about-form__part, .about-form__input-wrapper');
    const splited = form.querySelectorAll('.split-text span');
    const button = form.querySelector('.about-form__button');
    let timeout;
    const aboutFrame4Handler = () => {
      if ($(window).scrollTop() >= $(form).offset().top - 600)
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('about-frame-4', {
              form: form,
              parts: parts,
              splited: splited,
              button: button,
            });
          }, 100);
    };
    window.addEventListener('load', aboutFrame4Handler);
    window.addEventListener('scroll', aboutFrame4Handler);
  })();


  /*
  TEAM FRAME
  FRAME 5
  */
  (function() {
    const team = document.querySelector('.team');
    if (!team) return;
    const parts = team.querySelectorAll('.team__part');
    const teamItems = team.querySelectorAll('.team-item');
    const title = team.querySelector('.team__title')
    const splited = title.querySelectorAll('span');
    const itemHeight = teamItems[0].offsetHeight;
    BrowserDetect.init();
    if (BrowserDetect.browser == 'Explorer') {
      const imgs = team.querySelectorAll('.team-item__image');
      window.addEventListener('load', () => {
        for (let i = 0; i < imgs.length; i++) {
          filterIt(imgs[i]);
        }
      });
    }
    const tl = new TimelineLite();
    let titleShowed = false;
    const aboutFrame5ItemHandler = () => {
      const scroll = $(window).scrollTop();
      if ((!titleShowed) && (scroll >= $(title).offset().top - 600)) {
        titleShowed = true;
        tl.staggerTo(splited, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .05, "+=0");
      }
      for (let i = 0; i < teamItems.length; i++) {
        let item = teamItems[i];
        if ((!item.classList.contains('animated')) && (scroll >= $(teamItems[i]).offset().top - itemHeight - 600)) {
          item.classList.add('animated');
        }
      }
    }
    window.addEventListener('load', aboutFrame5ItemHandler);
    window.addEventListener('scroll', aboutFrame5ItemHandler);
  })();

  /*
  ABOUT SERVICES FRAME
  FRAME 6
  */
  (function() {
    const services = document.querySelector('.about-services');
    if (!services) return;
    const parts = services.querySelectorAll('.about-services__part');
    const items = services.querySelectorAll('.about-services__item');
    const splited = services.querySelectorAll('.split-text span');
    const bg = services.querySelector('.about-services__bg');
    let timeout;
    const aboutFrame6Handler = function() {
      if ($(window).scrollTop() >= $(services).offset().top - 600)
        if (!timeout)
          timeout = setTimeout(function() {
            app.fire('about-frame-6', {
              services: services,
              parts: parts,
              splited: splited,
              bg: bg,
              items: items
            });
          }, 100);
    }
    window.addEventListener('load', aboutFrame6Handler);
    window.addEventListener('scroll', aboutFrame6Handler);
  })();

}

export default aboutFrames;
