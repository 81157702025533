// opts = {
//   center: {
//     lat:55.714871,
//     lng:37.696635
//   },
//   zoom: 16,
//   scrollwheel: false
// }
// var marker = new google.maps.Marker({
//       position: {
//         lat: 48.8353343,
//         lng: 9.8216741,
//       },
//       map: map,
//       icon: '/images/map-marker.png'
//     });

class ContactsMap {
  constructor(node, mapOpts) {
    this.node = node;
    this.mapOpts = mapOpts;
  }
  init() {
    this.map = new google.maps.Map(this.node, this.mapOpts);
    this.styles = [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":"0"},{"color":"#5e5e60"},{"lightness":"0"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#29292a"},{"lightness":"0"}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#333334"},{"lightness":"0"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#29292a"},{"lightness":"0"},{"weight":"1.00"}]}]
    this.map.setOptions({styles: this.styles});
    new google.maps.Marker({
      position: this.mapOpts.center,
      map: this.map,
      icon: '/images/contacts/marker.png'
    });
  }
}
export default ContactsMap;
