const frame404 = function(window, document, app){
  (function(){
    const front = document.querySelector('.front-404 img');
    if (!front) return;
    const back = document.querySelector('.back-404 img');
    document.addEventListener('mousemove', (e) => {
      let middleY = document.body.clientHeight / 2 - 200;
      let middleX = document.body.clientWidth / 2;
      let y = e.pageY;
      let x = e.pageX;
      let needY = (y - middleY)/2 > 80 ? 80 : (y - middleY)/2;
      front.style.transform = `translate3d(${-(x - middleX)/12}px, ${-needY}px, 0)`;
      back.style.transform = `translate3d(${-(x - middleX)/12}px, ${-needY}px, 0)`;
    });
  })();
}

export default frame404;

// const frame404 = function(window, document, app){
//   (function(){
//     const front = document.querySelector('.front-404 img');
//     if (!front) return;
//     const back = document.querySelector('.back-404 img');
//     document.addEventListener('mousemove', (e) => {
//       let middleY = document.body.clientHeight / 2 - 200;
//       let middleX = document.body.clientWidth / 2;
//       let y = e.pageY;
//       let x = e.pageX;
//       let needY = (y - middleY)/2 > 250 ? 250 : (y - middleY)/2;
//       front.style.transform = `translate3d(${(x - middleX)/12}px, ${needY}px, 0)`;
//       back.style.transform = `translate3d(${(x - middleX)/12}px, ${needY}px, 0)`;
//     });
//   })();
// }
//
// export default frame404;
