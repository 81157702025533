/*
BLOG PAGE FRAME ANIMATION
*/

const blogPageFrames = function(window, document, app, TimelineLite, Vivus, Back){
  app.on('blog-page-frame-1', ({header, splitedHeaderText, headerParts, breadcrumbs, splitHeader}) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      console.log(splitHeader);
      const tl1 = new TimelineLite();
      tl1.staggerTo(splitHeader, 1, {
        opacity: 1,
        ease: Back.easeOut
      }, .1, '+=0');
      setTimeout(()=>{
        breadcrumbs.classList.add('animated');
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .05, '+=0');
        app.fire('blog-page-frame-1-end');
      }, 1200);
    });
  });
  /*
  HEADER SCREEN
  */
  (function(){
    const header = document.querySelector('.header--blog-page');
    if (!header) return;
    const splitHeader = header.querySelectorAll('.header__text .split-text span');
    // const splitedHeaderText = document.querySelectorAll('.blog-page-title span, .blog-page-date span');
    const splitedHeaderText = [].slice.call(document.querySelectorAll('.blog-page-title span')).concat([].slice.call(document.querySelectorAll('.blog-page-date span')));
    const headerParts = header.querySelectorAll('.header__part');
    const breadcrumbs = header.querySelector('.breadcrumbs');
    const blogPageFrame1Handler = () => {
      app.fire('blog-page-frame-1', {
        header: header,
        splitedHeaderText: splitedHeaderText,
        headerParts: headerParts,
        breadcrumbs: breadcrumbs,
        splitHeader: splitHeader
      });
    }
    // window.addEventListener('load', blogPageFrame1Handler);
    blogPageFrame1Handler()
  })();


  /*
  CONTENT TAGS FADE EFFECT
  */
  (function(){
    const blog = document.querySelector('.blog-page');
    if (!blog) return;
    const items = blog.children;
    const svgId = 'blog-page-svg-logo';
    const blogButton = document.querySelector('.blog-button');
    const blogButtonWrapper = blogButton.parentElement;

    const blogPageItemsHandler = () => {
      let scroll = $(window).scrollTop();
      let offset = app.width()>1366 ? 900 : app.width()<=1280 ? 900 : 600;
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if ( ( !item.classList.contains('animated') ) && ( scroll >= $(item).offset().top - offset) ) {
          item.classList.add('animated');
          if (item.classList.contains('blog-page__svg-wrapper')) {
            new Vivus(svgId, {duration: 1000, type: 'async', start: 'autostart'});
          }
        }
      }
      if (scroll >= $(blogButtonWrapper).offset().top - 800)
        blogButton.classList.add('animated');
    }
    app.on('blog-page-frame-1-end', () => {
      blogPageItemsHandler();
      window.addEventListener('scroll', blogPageItemsHandler);
    });
  })();

}

export default blogPageFrames;
