/*
PORTFOLIO PAGE FRAME ANIMATION
*/

const portfolioPageFrames = function(window, document, app, TimelineLite, Vivus, Back) {

  app.on('portfolio-page-frame-1', ({
    header,
    splitedHeaderText,
    headerParts,
    breadcrumbs,
    splited,
    svgId
  }) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(() => {
        new Vivus(svgId, {
          duration: 1000,
          type: 'async',
          start: 'autostart'
        });
        breadcrumbs.classList.add('animated');
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .05, '+=0');
        setTimeout(() => {
          app.fire('portfolio-page-frame-1-end', {
            splited: splited
          });
        }, 500);
      }, 1200);
    });
  });
  app.on('portfolio-page-frame-1-end', ({
    splited
  }) => {
    const tl = new TimelineLite();
    tl.staggerTo(splited, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .025, '+=0');
  });

  app.on('portfolio-page-frame-2', ({
    parts,
    svgs,
    svgLogo
  }) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    for (let i = 0; i < svgs.length; i++) {
      new Vivus(svgs[i], {
        duration: 500,
        type: 'async',
        start: 'autostart'
      });
    }
    new Vivus(svgLogo, {
      duration: 1000,
      type: 'async',
      start: 'autostart'
    });
  });
  app.on('portfolio-page-frame-3', ({
    parts,
    splitedTitle,
    splited,
    svgId,
    svgWrapper
  }) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    svgWrapper.classList.add('animated');
    let tl = new TimelineLite();
    new Vivus(svgId, {
      duration: 1000,
      type: 'async',
      start: 'autostart'
    });
    tl.staggerTo(splitedTitle, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .1, '+=0');
    let tl1 = new TimelineLite();
    tl1.staggerTo(splited, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .005, '+=0');
  });

  /*
  HEADER SCREEN
  */
  (function() {
    const header = document.querySelector('.header--portfolio-page');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.header__text .split-text span')
    const headerParts = header.querySelectorAll('.header__part');
    const breadcrumbs = header.querySelector('.breadcrumbs');
    // const splited = document.querySelectorAll('.portfolio-page-title span, .portfolio-page-description .split-text span');
    const splited = [].slice.call(document.querySelectorAll('.portfolio-page-title span')).concat([].slice.call(document.querySelectorAll('.portfolio-page-description .split-text span')));
    const svgId = 'header-svg';

    const portfolioPageFrame1Handler = () => {
        app.fire('portfolio-page-frame-1', {
          header: header,
          splitedHeaderText: splitedHeaderText,
          headerParts: headerParts,
          breadcrumbs: breadcrumbs,
          splited: splited,
          svgId: svgId
        });
      }
      // window.addEventListener('load', portfolioPageFrame1Handler);
    portfolioPageFrame1Handler();
  })();

  /*
  PORTFOLIO PAGE CONTENT
  */
  (function() {
    const page = document.querySelector('.portfolio-page');
    if (!page) return;
    const parts = page.querySelectorAll('.portfolio-page__part');
    const block1 = page.querySelector('.portfolio-page__part--2 .portfolio-text-block');
    const block2 = page.querySelector('.portfolio-page__part--3 .portfolio-text-block');
    const block3 = page.querySelector('.portfolio-page__part--4 .portfolio-text-block');
    const svgs = ['portfolio-page-svg-1', 'portfolio-page-svg-2', 'portfolio-page-svg-3'];
    const svgLogo = 'portfolio-page-svg-logo';

    const setMargins = function() {
      let svg2 = document.getElementById(svgs[1]).parentElement;
      let svg3 = document.getElementById(svgs[2]).parentElement;
      if (app.width() > 768) {
        block2.style.marginTop = `${block1.offsetHeight + 80}px`;
        block3.style.marginTop = `${block1.offsetHeight + block2.offsetHeight + 160}px`;
        svg2.style.top = `${block1.offsetHeight + 90}px`;
        svg3.style.top = `${block1.offsetHeight + block2.offsetHeight + 169}px`;
      }

    }

    let timeout;
    const portfolioPageFrame2Handler = () => {
      let offset = app.width() <= 1366 && app.width() > 1280 ? 500 : app.width() == 1280 ? 600 : 700;
      if ($(window).scrollTop() >= $(page).offset().top - offset)
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('portfolio-page-frame-2', {
              parts: parts,
              svgs: svgs,
              svgLogo: svgLogo
            });
          }, 100);
    }

    window.addEventListener('load', setMargins);
    window.addEventListener('load', portfolioPageFrame2Handler);
    window.addEventListener('scroll', portfolioPageFrame2Handler);
  })();


  /*
  RESULTS SLIDER HANDLERS
  */

  (function() {
    const gallery = document.querySelector('.result-gallery');
    if (!gallery) return;
    const arrows = gallery.querySelector('.result-gallery__arrows');
    const back = gallery.querySelector('.result-gallery__back');
    const front = gallery.querySelector('.result-gallery__front');
    const backSlides = gallery.querySelectorAll('.result-gallery__back-slide');
    const frontSlides = gallery.querySelectorAll('.result-gallery__front-slide');
    const backImgs = gallery.querySelectorAll('.result-gallery__back img');
    const frontImgs = gallery.querySelectorAll('.result-gallery__front img');
    const next = gallery.querySelector('.result-gallery__arrow-next');
    const prev = gallery.querySelector('.result-gallery__arrow-prev');

    const resulstsGalleryHandler = function() {
      arrows.style.top = `${back.offsetHeight}px`;
      arrows.style.right = `${front.offsetWidth + parseInt(getComputedStyle(front).right)}px`;
      for (let i = 0; i < backImgs.length; i++) {
        backImgs[i].style.width = backSlides[0].offsetWidth + 'px';
        frontImgs[i].style.width = frontSlides[0].offsetWidth + 'px';
      }
    }

    window.addEventListener('load', resulstsGalleryHandler);
    // window.addEventListener('resize', resulstsGalleryHandler);

    let current = 0;
    for (let i = 1; i < backSlides.length; i++) {
      backSlides[i].classList.add('hide');
      frontSlides[i].classList.add('hide');
    }
    backSlides[0].classList.add('show');
    frontSlides[0].classList.add('show');
    const length = backSlides.length;
    let timeout;
    let globalInterval;
    let inAction = false;
    const initInterval = function() {
      globalInterval = setInterval(() => {
        navTo('next')();
      }, 8000);
    }
    const resetInterval = function() {
      clearInterval(globalInterval);
      initInterval();
    }
    const navTo = function(direction) {
      return function(e) {
        switch (direction) {
          case 'next':
            if (!inAction) {
              backSlides[current].classList.remove('show');
              backSlides[current].classList.add('hide');
              frontSlides[current].classList.remove('show');
              frontSlides[current].classList.add('hide');
              current = (current == length - 1) ? 0 : current + 1;
              if (timeout)
                clearTimeout(timeout);
              timeout = setTimeout(() => {
                resetInterval();
                backSlides[current].classList.add('show');
                frontSlides[current].classList.add('show');
                setTimeout(() => {
                  inAction = false;
                }, 800);
              }, 800);
              inAction = true;
            }
            break;
          case 'prev':
            if (!inAction) {
              backSlides[current].classList.remove('show');
              backSlides[current].classList.add('hide');
              frontSlides[current].classList.remove('show');
              frontSlides[current].classList.add('hide')
              current = (current == 0) ? length - 1 : current - 1;
              if (timeout)
                clearTimeout(timeout);
              timeout = setTimeout(() => {
                resetInterval();
                backSlides[current].classList.add('show');
                frontSlides[current].classList.add('show');
                setTimeout(() => {
                  inAction = false;
                }, 800);
              }, 800);
              inAction = true;
            }
            break;
          default:
            break;
        }
      }
    }
    next.addEventListener('click', navTo('next'));
    prev.addEventListener('click', navTo('prev'));
    initInterval();
  })();

  /*
  PORTFOLIO PAGE FANCYBOX
  */
  (function() {
    const result = document.querySelector('.result');
    if (!result) return;
    const parts = result.querySelectorAll('.result__text-wrapper, .result__part');
    const splitedTitle = result.querySelectorAll('.result__title span');
    const splited = result.querySelectorAll('.result__text .split-text span');
    const svgId = 'result-svg';
    const svgWrapper = result.querySelector('.result__svg-wrapper');

    const button = document.querySelector('.blog-button');
    const items = result.querySelectorAll('.result-item');
    const popup = document.querySelector('.result-popup');
    const inner = popup.querySelector('.result-popup__inner');
    const output = popup.querySelector('.result-popup__output');
    const spinners = popup.querySelector('.result-popup__spinners');

    const resultHrefs = [].slice.call(items).map((el) => {
      return el.getAttribute('href');
    });


    let timeout;
    const portfolioPageFrame3Handler = () => {
      let offset = app.width() <= 1366 && app.width() > 1280 ? 500 : 700;
      if ($(window).scrollTop() >= $(result).offset().top - offset) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('portfolio-page-frame-3', {
              parts: parts,
              splited: splited,
              splitedTitle: splitedTitle,
              svgId: svgId,
              svgWrapper: svgWrapper
            })
          }, 100);
      }
      if ($(window).scrollTop() >= $(button.parentElement).offset().top - 700 && !button.classList.contains('animated')) {
        button.classList.add('animated');
      }
    }
    window.addEventListener('load', portfolioPageFrame3Handler);
    window.addEventListener('scroll', portfolioPageFrame3Handler);
    let inprogress = false;
    const putMiddle = function(img, maxW, maxH) {
      let ow, oh;
      let w = img.naturalWidth;
      let h = img.naturalHeight;
      let propotion = w / h;
      if (w >= maxW || h >= maxH) {
        if (w >= maxW) {
          ow = maxW - 40;
          oh = ow / propotion;
          if (oh >= maxH) {
            oh = maxH - 40;
            ow = propotion * oh;
          }
        }
        if (h >= maxH) {
          oh = maxH - 40;
          ow = propotion * oh;
          if (ow >= maxW) {
            ow = maxW - 40;
            oh = ow / propotion;
          }
        }
        img.setAttribute('width', ow);
        img.setAttribute('height', oh.toFixed(0));
      }
    }

    let currentIndex, nextIndex, prevIndex;
    const nextArrow = popup.querySelector('.result-popup__next');
    const prevArrow = popup.querySelector('.result-popup__prev');
    let popupIsOpen = false;
    const itemHandler = function(e) {
      if (e) {
        e.preventDefault();
      }
      if (inprogress) return;
      popupIsOpen = true;
      spinners.style.opacity = '1';
      const maxH = document.body.clientHeight;
      const maxW = document.body.clientWidth;
      const src = this.getAttribute('href');
      let current = resultHrefs.indexOf(src);
      nextIndex = (current + 1) === resultHrefs.length ? 0 : current + 1;
      prevIndex = (current - 1) < 0 ? resultHrefs.length - 1 : current - 1;
      console.log(prevIndex,nextIndex);
      nextArrow.setAttribute('href', resultHrefs[nextIndex]);
      prevArrow.setAttribute('href', resultHrefs[prevIndex]);
      const img = new Image();
      img.src = src;
      img.style.visibility = 'hidden';
      img.style.opacity = '0';
      output.appendChild(img);
      popup.classList.add('active');
      inprogress = true;
      img.onload = function() {
        putMiddle(img, maxW, maxH);
        img.style.visibility = 'visible';
        img.style.opacity = '1';
        spinners.style.opacity = '0';
        inprogress = false;
      }

    }
    nextArrow.setAttribute('href', resultHrefs[2]);
    prevArrow.setAttribute('href', resultHrefs[0]);
    for (let i = 0; i < items.length; i++) {
      items[i].addEventListener('click', itemHandler);
    }
    nextArrow.addEventListener('click', function(e) {
      output.innerHTML = '';
      if (e) {
        e.stopPropagation();
      }
      itemHandler.call(this, e);
    });
    prevArrow.addEventListener('click', function(e) {
      output.innerHTML = '';
      if (e) {
        e.stopPropagation();
      }
      itemHandler.call(this, e);
    });
    popup.addEventListener('click', function() {
      this.classList.remove('active');
      output.innerHTML = '';
      popupIsOpen = false;
    });
    output.addEventListener('click', (e) => {
      e.stopPropagation();
      nextArrow.click();
    });
    document.addEventListener('keydown', function(e){
      if (!popupIsOpen) return;
      if (e.keyCode === 37) {
        prevArrow.click();
      } else if (e.keyCode === 39) {
        nextArrow.click();
      } else if (e.keyCode === 27) {
        popup.click()
      }
    });
  })();

}

export default portfolioPageFrames;
