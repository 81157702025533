import Observer from './observer';
import SplitText from './split-text';
import CasesGallery from './cases-gallery';
import aboutFrames from './about'; /*about page frame animation handlers*/
import authorFrames from './author'; /*author page frame animation handlers*/
import complectFrames from './complect'; /*complect page frame animation handlers*/
import remontFrames from './remont'; /*remont page frame animation handlers*/
import complexFrames from './complex'; /*complex page frame animation handlers*/
import blogFrames from './blog'; /*blog index page frame animation handlers*/
import blogPageFrames from './blog-page'; /*blog page frame animation handlers*/
import portfolioFrames from './portfolio'; /*portfolio index page frame animation handlers*/
import partnersFrames from './partners'; /*partners index page frame animation handlers*/
import portfolioPageFrames from './portfolio-page'; /*portfolio page frame animation handlers*/
import frame404 from './page-404'; /*404 page frame handlers*/
import contactsFrames from './contacts'; /*contacts page frame animation handlers*/
import BrowserDetect from './browser-detect'; /*detect your browser*/
// require('./markup-menu')(window.document);

const app = window.app = new Observer();
app.on('frame-0', ({grids})=>{
  for (let i = 0; i < grids.length; i++) {
    grids[i].classList.add('animated');
  };
  setTimeout(() => {
    app.fire('frame-0-end');
  }, 1000);
});
app.on('frame-1', function({grids, parts, headerTitle, headerSubtitle, headerShadow, video}){

  setTimeout(()=>{
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    if (app.width()>1024)
      setTimeout(()=>{
        for (let i = 0; i < parts.length; i++) {
          parts[i].classList.add('no-background');
          parts[i].removeAttribute('style');
        }
        video.play();
      },3000);
    // app.fire('frame-1-end');
  }, 2000);
  setTimeout(()=>{
    var tl = new TimelineLite();
    var tlShadow = new TimelineLite();
    new Vivus('header-svg', {duration: 1000, type: 'async', start: 'autostart'});
    tlShadow.staggerFromTo(headerShadow, .8,{
      opacity:0,
    }, {
      opacity:1,
      ease:Back.easeOut
    }, .05, "+=0");
    tl.staggerFromTo([].slice.call(headerTitle).concat([].slice.call(headerSubtitle)), .8,{
      opacity:0,
    }, {
      opacity:1,
      ease:Back.easeOut
    }, .05, "+=0");
  }, 3500);
});

app.on('frame-2', function({homeDesign, parts, block, link, splitNodes, heading, lis, helper}) {
  for (let i = 0; i < parts.length; i++) {
    parts[i].classList.add('animated');
  }
  setTimeout(()=>{
    block.classList.add('animated');
  }, 1000);
  setTimeout(()=>{
    let tl = new TimelineLite();
    tl.staggerTo(heading, 2, {
        opacity:1,
        ease:Back.easeOut
      }, .1, "+=0"
    );
    let observer = new Observer();
    let needLength = lis.length;
    let counter = 0;
    observer.on('li-animation-finished', function(){
      for (let i = 0; i < lis.length; i++) {
        lis[i].classList.add('animated');
      }
    });
    for (let i = 0; i < splitNodes.length; i++) {
      let splited = splitNodes[i].querySelectorAll('span');
      let tl = new TimelineLite();
      tl.staggerTo(splited, .8, {
        opacity:1,
        ease:Back.easeOut
      }, .025, "+=0", function(){
        let elem = this._first.target.parentElement;
        if (elem.nodeName.toLowerCase() === 'li') {
          counter++;
          if (counter === needLength-2) observer.fire('li-animation-finished');
        }
      });
    }
  }, 1000);
  setTimeout(()=>{
    link.classList.add('animated');
  }, 2000);
  setTimeout(()=>{
    helper.classList.add('animated');
  }, 4000)
});

app.on('frame-3', function({homeServices, parts, svgId, items}){
  for (let i = 0; i < parts.length; i++) {
    parts[i].classList.add('animated');
  }
  let intervalCount = 0;
  let interval;
  interval = setInterval(()=>{
    items[intervalCount++].classList.add('animated');
    if (intervalCount===3) {
      clearInterval(interval);
      new Vivus('home-services-svg', {duration: 1000, type: 'async', start: 'autostart'});
    }
  }, 500)
});

app.on('frame-4', function({casesGallery, parts, svgId, gallery, splitNodes}){
  for (let i = 0; i < parts.length; i++) {
    parts[i].classList.add('animated');
  }
  new Vivus(svgId, {duration: 1000, type: 'async', start: 'autostart'});
  casesGallery.classList.add('animated');
  setTimeout(()=>{
    gallery.render();
    for (let i = 0; i < splitNodes.length; i++) {
      let splited = splitNodes[i].querySelectorAll('span');
      let tl = new TimelineLite();
      tl.staggerTo(splited, .8, {
        opacity:1,
        ease:Back.easeOut
      }, .1, "+=0");
    }
  }, 1000);
});

app.on('frame-5', function({mission, helper, splited, slidesRender, button}){
  mission.classList.add('animated');
  slidesRender();
  setTimeout(()=>{
    button.classList.add('animated');
  }, 1000);
  setTimeout(()=>{
    helper.classList.add('animated');
  }, 2000);
  const tl = new TimelineLite();
  tl.staggerTo(splited, .8, {
    opacity:1,
    ease:Back.easeOut
  }, .03, "+=0");
});

app.on('frame-6', function({images, nameOutput, descriptionOutput, names, descriptions, info, rect, output, leftGallery, rightGallery, inscription, svgId, border, helper, link, parts}){
  leftGallery.appendChild(images[0]);
  rightGallery.appendChild(images[1]);
  output.appendChild(images[2]);
  nameOutput.innerHTML = names[2];
  let splited = app.width()>1024 ? new SplitText(nameOutput) : [];
  descriptionOutput.innerHTML = descriptions[2];
  for (let i = 0; i < parts.length; i++) {
    parts[i].classList.add('animated');
  }
  let mainParts = [leftGallery, rightGallery, output, info, rect, border, inscription, helper, link];
  const tl = new TimelineLite();
  setTimeout(()=>{
    for (let i = 0; i < mainParts.length; i++) {
      $(mainParts[i]).addClass('animated');
    }
    tl.staggerTo(splited, .8, {
      opacity:1,
      ease:Back.easeOut
    }, .03, "+=1");
    leftGallery.addEventListener('click', galleryHandler);
    rightGallery.addEventListener('click', galleryHandler);
  }, 1000);
  let inAction = false;
  new Vivus(svgId, {duration: 1000, type: 'async', start: 'autostart'});
  let galleryHandler = function(e){
    if (inAction) return;
    let target = e.target;
    if (target.nodeName!=='IMG') return;
    let index = target.getAttribute('data-employee');
    let img = output.querySelector('img');
    $([leftGallery, rightGallery, output, info, rect]).removeClass('animated');
    tl.staggerTo(splited, .8, {
      opacity:0,
      ease:Back.easeOut
    }, .03, "+=0");
    inAction = true;
    setTimeout(()=>{
      output.innerHTML = '';
      descriptionOutput.innerHTML = descriptions[index];
      output.appendChild(target);
      this.appendChild(img);
      nameOutput.innerHTML = names[index];
      splited = app.width()>1024 ? new SplitText(nameOutput) : [];
      tl.staggerTo(splited, .8, {
        opacity:1,
        ease:Back.easeOut
      }, .03, "+=0");
      $([leftGallery, rightGallery, output, info, rect]).addClass('animated');
      setTimeout(()=>{
        inAction = false;
      }, 800);
    }, 1200);
  }

});

app.on('frame-7', function({articles, parts, title, row}){
  let counter = 0;
  title.classList.add('animated');
  row.classList.add('animated');
  const interval = setInterval(()=>{
    parts[counter++].classList.add('animated');
    if (counter == parts.length) clearInterval(interval);
  }, 300);

});

app.on('frame-8', ({footer, gridItems, parts, svgId, serifs})=>{
  /*сначала .footer-grid__item
    700 - .footer__part, vivus 'footer-svg'
    2000 - .footer .serif

  */
  for (let i = 0; i < gridItems.length; i++) {
    gridItems[i].classList.add('animated');
  }
  setTimeout(function () {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
  }, 700);
  setTimeout(function () {
    for (let i = 0; i < serifs.length; i++) {
      serifs[i].classList.add('animated');
      new Vivus(svgId, {duration: 1000, type: 'async', start: 'autostart'});
    }
  }, 2000);
});


app.on('close-modal', ({modalOverlay, modal, form})=>{
  modalOverlay.classList.remove('active');
  modal.classList.remove('active');
  form.classList.remove('animated');
});

app.on('show-modal', ({modalOverlay, modal, menu, menuOverlay, form, svgInspritionId, svgLogoId, splited})=>{
  modalOverlay.classList.add('active');
  modal.classList.add('active');
  app.menu.classList.remove('active');
  app.menuOverlay.classList.remove('active');
  form.classList.add('animated');
  for (let i = 0; i < splited.length; i++) {
    splited[i].style.opacity = 0;
  }
  new Vivus(svgInspritionId, {duration: 1000, type: 'async', start: 'autostart'});
  new Vivus(svgLogoId, {duration: 1000, type: 'async', start: 'autostart'});
  const tl = new TimelineLite();
  tl.staggerTo(splited, .8, {
    opacity:1,
    ease:Back.easeOut
  }, .03, "+=0");
});
$(function(){
  'use strict';
  app.width = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  /*
  VIVUS SETTINGS FOR  SCREENS <= 1024px
  */
  (function(){
    const init = Vivus.prototype.init;
    Vivus.prototype.init = function(){
      if (app.width()<=1024)
        return;
      else
        init.apply(this, arguments);
    }
  })();

  /*
  SVG FOR IE
  */
  (function(){
    if (window.svg4everybody)
      svg4everybody();
  })();

  /*
  REMOVE STYLE FROM HEADER PARTS AND ADD TO HEADER
  */
  (function(){
    const header = document.querySelector('.header');
    if (!header) return;
    const parts = header.querySelectorAll('.header__part');
    const style = header.getAttribute('data-style');
    if (style===null)
      return;
    const removeStyleFormParts = () => {
      if (app.width() <= 1024) {
        for (let i = 0; i < parts.length; i++) {
          parts[i].removeAttribute('style');
        }
        header.setAttribute('style', style);
      } else {
        header.removeAttribute('style');
        for (let i = 0; i < parts.length; i++) {
          parts[i].setAttribute('style', style)
        }
      }
    }
    // window.addEventListener('load', removeStyleFormParts);
    removeStyleFormParts();
    window.addEventListener('resize', removeStyleFormParts);
  })();


  /*
  PRELOADER HANDLERS
  */
  app.on('app-ready', ({preloader, progress, svgWrapper, parts}) => {
    window.scrollTo(0,0);
    setTimeout(()=>{
      for (var i = 0; i < parts.length; i++) {
        parts[i].classList.add('animated');
      }
      progress.classList.add('animated');
      svgWrapper.classList.add('animated');
    }, 1000);
  });
  app.on('show-preloader', ({href, preloader, parts}) => {
    for (var i = 0; i < parts.length; i++) {
      parts[i].classList.remove('animated');
    }
    if (href)
      setTimeout(()=>{
        window.location.href = href;
      }, 1500);
  });
  (function(){
    let isPreloader = true;
    localStorage.setItem('preloader', isPreloader);
    // document.addEventListener('keyup', function(e) {
    //   if (e.ctrlKey && e.altKey && e.keyCode == '90') {
    //     isPreloader = !isPreloader;
    //     localStorage.setItem('preloader', isPreloader);
    //   }
    // });
    // if (isPreloader) {
    //   document.addEventListener('keyup', function(e) {
    //     if (e.ctrlKey && e.altKey && e.keyCode == '80') {
    //       app.fire('show-preloader', {href: null, preloader: preloader, parts: parts})
    //     }
    //   });
    // }
    const preloader = document.getElementById('preloader');
    if (!preloader) return;
    if (!isPreloader) {
      preloader.remove ? preloader.remove() : preloader.parentElement.removeChild(preloader);
      return;
    }
    const imgs = window.imgs = document.getElementsByTagName('img');
    const progress = preloader.querySelector('.preloader__progress');
    const progressBar = preloader.querySelector('.preloader__progress-bar');
    const svgWrapper = preloader.querySelector('.preloader__svg-wrapper');
    const parts = preloader.querySelectorAll('.preloader__part');

    const length = imgs.length;
    const coeff = 100/length;
    let count = 0;
    if (imgs.length) {
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i].complete) {
          progressBar.style.height = (++count * coeff) + '%';
          if (count===length)
            app.fire('app-ready', {
              preloder: preloader,
              progress: progress,
              svgWrapper: svgWrapper,
              parts: parts,
            });
        } else {
          imgs[i].onload = () => {
            progressBar.style.height = (++count * coeff) + '%';
            if (count===length)
              app.fire('app-ready', {
                preloder: preloader,
                progress: progress,
                svgWrapper: svgWrapper,
                parts: parts,
              });
          }
        }
      }
    } else {
      progressBar.style.height = '100%';
      // setTimeout(()=>{
        app.fire('app-ready', {
          preloder: preloader,
          progress: progress,
          svgWrapper: svgWrapper,
          parts: parts,
        });
      // }, 1000);
    }
    document.addEventListener('click', function(e){
      const target = e.target;
      if (target.tagName === 'A' && target.getAttribute('data-preloader')!==null) {
        const href = target.getAttribute('href');
        const preloader = document.getElementById('preloader');
        if (preloader && app.width() > 1024)
          e.preventDefault();
        const parts = preloader.querySelectorAll('.preloader__part');
        app.fire('show-preloader', {
          preloader: preloader,
          parts: parts,
          href: href
        });
        // window.location.href = href;
      }
    })
  })();
  /*
  JCF REPLACER
  */
  (function(){
    if (window.jcf) {
      jcf.replaceAll();
    }
  })();



  /*
  TEXT SPLITTER
  */
  (function(){
    const slpited = document.querySelectorAll('.split-text');
    if (app.width()<=1024) return;
    if (!slpited.length) return;
    for (let i = 0; i < slpited.length; i++) {
      new SplitText(slpited[i]);
    }
  })();

  /*
  SUBLIST OPEN HANDLER
  */
  (function(){
    const navLinksWithSublist = document.querySelectorAll('.menu__list-link--with-sublist');
    if (!navLinksWithSublist.length) return;
    for (let i = 0; i < navLinksWithSublist.length; i++) {
      navLinksWithSublist[i].addEventListener('click', function(e){
        e.preventDefault();
        this.classList.toggle('open');
      });
    }
  })();

  /*
  MENU OPEN HANDLER
  */
  (function(){
    const menuButton = document.querySelector('.aside__menu-button');
    const menu = document.querySelector('.menu');
    const menuOverlay = document.querySelector('.menu-overlay');
    const closeButton = menu.querySelector('.menu__close');
    const closeMenu = ()=>{
      menu.classList.remove('active');
      menuOverlay.classList.remove('active');
    }
    menuButton.addEventListener('click', ()=>{
      menu.classList.add('active');
      menuOverlay.classList.add('active');
    });
    menuOverlay.addEventListener('click', closeMenu);
    closeButton.addEventListener('click', closeMenu);
    app.menu = menu;
    app.menuOverlay = menuOverlay;
  })();
  /*
  SCROLL TOP ARROW CLICK HANDLER
  */
  (function(){
    const arrow = document.querySelector('.footer__part--4 .arrow');
    if (!arrow) return;
    arrow.addEventListener('click', ()=>{
      $('html, body').animate({
        scrollTop: 0
      }, 1000);
    });
  })();

  /*
  REQUEST ORDER MODAL
  */
  (function(){
    const modalOverlay = document.querySelector('.modal-overlay');
    const modal = document.querySelector('.modal');
    const form = modal.querySelector('.modal__form');
    const buttons = document.querySelectorAll('.menu__callback-button, .mission__button, .cost-content__button');
    const closeButton = modal.querySelector('.modal__close');
    const svgInspritionId = 'modal-svg-insprition';
    const svgLogoId = 'modal-svg-logo';
    const splited = modal.querySelectorAll('.split-text span');

    const closeModal = ()=>{
      app.fire('close-modal', {
        modalOverlay: modalOverlay,
        modal: modal,
        form: form,
      });
    };
    modal.addEventListener('click', closeModal);
    closeButton.addEventListener('click', closeModal);
    form.addEventListener('click', (e)=>{
      e.stopPropagation();
    });
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', (e)=>{
        e.preventDefault();
        app.fire('show-modal', {
          modalOverlay: modalOverlay,
          modal: modal,
          menu: app.menu,
          menuOverlay: app.menuOverlay,
          form: form,
          svgInspritionId:svgInspritionId,
          svgLogoId:svgLogoId,
          splited: splited
        })
      });
    }
  })();

  /*
  FORM LABEL AND INPUTS HANDLER
  */
  (function() {
    const checkInputs = function(inputs){
      for (let i = 0; i < inputs.length; i++) {
        const div = inputs[i].parentElement;
        const label = div.querySelector('label');
        if (inputs[i].value == '') label.classList.remove('active');
      }
    }
    const activeLabelHandler = function(e) {
      const target = e.target;
      const nodeName = target.nodeName.toLowerCase();
      let label;
      if ((nodeName == 'input' || nodeName == 'textarea') && target.getAttribute('type')!=='hidden') {
        let div = target.parentElement;
        label = div.querySelector('label');
      } else if (nodeName == 'label') {
        label = target;
      }
      if (label) label.classList.add('active');
      if (e.type == 'blur') {
        checkInputs(this);
      }
    };
    const forms = document.querySelectorAll('form');
    if (!forms.length) return;
    for (let i = 0; i < forms.length; i++) {
      let inputs = forms[i].querySelectorAll('input:not([type="hidden"]), textarea');
      forms[i].addEventListener('click', activeLabelHandler.bind(inputs));
      forms[i].addEventListener('blur', activeLabelHandler.bind(inputs), true);
      forms[i].addEventListener('focus', activeLabelHandler.bind(inputs), true);
    }
  })();

  /*
  GRID ANIMATION HANDLER
  */
  (function(){
    const grids = document.querySelectorAll('.grid__item');
    const gridHandler = () => {
      app.fire('frame-0', { grids: grids });
    }
    // window.addEventListener('load', gridHandler);
    gridHandler()
  })();

  /*
  BREADCRUMBS HOVER
  */
  (function(){
    const breadcrumbs = document.querySelector('.breadcrumbs');
    if (!breadcrumbs) return;
    const links = breadcrumbs.querySelectorAll('.breadcrumbs__link');
    // for (var i = 0; i < link.length; i++) {
    //   link[i]
    // }
    breadcrumbs.addEventListener('mouseover', (e) => {
      const target = e.target;
      if (target.tagName.toLowerCase() === 'a') {
        target.parentElement.classList.add('hover');
      }
    });
    breadcrumbs.addEventListener('mouseout', (e) => {
      const hovered = breadcrumbs.querySelectorAll('.hover');
      for (let i = 0; i < hovered.length; i++) {
        hovered[i].classList.remove('hover');
      }
    });
  })();

  /*
  MOBILE HANDLERS
  */

  // (function(){
  //   const aside = document.querySelector('.aside');
  //   if (!aside) return;
  //   let asideIsMobile = false;
  //   const asideMobileHandler = (e) => {
  //     e.stopPropagation();
  //     e.preventDefault();
  //     app.menu.classList.add('active');
  //     app.menuOverlay.classList.add('active');
  //   }
  //   const checkMobile = () => app.width() <= 1024;
  //   const mobileHandler = () => {
  //     if (checkMobile()) {
  //       if (!asideIsMobile) {
  //         aside.addEventListener('click', asideMobileHandler);
  //         asideIsMobile = true;
  //       }
  //     } else {
  //       if (asideIsMobile) {
  //         aside.removeEventListener('click', asideMobileHandler);
  //         asideIsMobile = false;
  //       }
  //     }
  //   }
  //   window.addEventListener('load', mobileHandler);
  //   window.addEventListener('resize', mobileHandler);
  // })();


  /*
  FRAMES ON "ABOUT PAGE"
  */
  aboutFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "AUTHOR PAGE"
  */
  authorFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "COMPLECT PAGE"
  */
  complectFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "REMONT PAGE"
  */
  remontFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "COMPLEX PAGE"
  */
  complexFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "BLOG INDEX PAGE"
  */
  blogFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "BLOG SINGLE PAGE"
  */
  blogPageFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "PORTFOLIO INDEX PAGE"
  */
  portfolioFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "PORTFOLIO INDEX PAGE"
  */
  partnersFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "PORTFOLIO PAGE"
  */
  contactsFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  FRAMES ON "PORTFOLIO PAGE"
  */
  portfolioPageFrames(window, document, app, TimelineLite, Vivus, Back);

  /*
  ANIMATION ON "404 PAGE"
  */
  frame404(window, document, app);

  /*
  HOME HEADER FRAME (HOME SCREEN 1)
  */
  (function(){
    const header = document.querySelector('.header--home');
    if (!header) return;
    const parts = header.children;
    const headerText = header.querySelector('.header__text');
    if (app.width()<=1024)
      parts[1].appendChild(headerText);
    const headerTitle = document.querySelectorAll('.header__title span');
    const headerSubtitle = document.querySelectorAll('.header__subtitle span');
    const headerShadow = document.querySelectorAll('.header__title-shadow span');
    const video = document.querySelector('.header__video');
    // window.addEventListener('load', function(){
      setTimeout(function () {
        app.fire('frame-1', {
          parts: parts,
          headerTitle: headerTitle,
          headerSubtitle: headerSubtitle,
          headerShadow: headerShadow,
          video: video
        });
      // }, 100);
    });
  })();

  /*
  HOME DESIGN FRAME (HOME SCREEN 2)
  */
  (function(){
    const homeDesign = document.querySelector('.home-design');
    if (!homeDesign) return;
    const parts = homeDesign.children;
    const block = homeDesign.querySelector('.home-design__block');
    const link = homeDesign.querySelector('.home-design__link');
    const splitNodes = homeDesign.querySelectorAll('.split-text:not(.home-design__title):not(.home-design__subtitle)');
    const headingSplit = homeDesign.querySelectorAll('.home-design__title span, .home-design__subtitle span');
    const lis = homeDesign.querySelectorAll('.home-design__list-item');
    const helper = homeDesign.querySelector('.home-design__block-helper');
    let timeout;
    let frame2Handler = function(){
      let offset = app.width()>1366? 600 : 400;
      if ($(window).scrollTop() >= $(homeDesign).offset().top - offset)
        if (!timeout)
          timeout = setTimeout(function () {
            app.fire('frame-2', {
              homeDesign: homeDesign,
              parts: parts,
              block: block,
              link: link,
              splitNodes: splitNodes,
              heading: headingSplit,
              lis: lis,
              helper: helper
            });
          }, 100);
    }
    window.addEventListener('load', frame2Handler);
    window.addEventListener('scroll', frame2Handler);
  })();

  /*
  HOME SERVICES FRAME (HOME SCREEN 3)
  */
  (function(){
    const homeServices = document.querySelector('.home-services');
    if (!homeServices) return;
    const parts = homeServices.children;
    const svgId = 'home-services-svg';
    const items = homeServices.querySelectorAll('.home-services__item');
    let timeout;
    const frame3Handler = function(){
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(homeServices).offset().top - offset)
      if (!timeout)
        timeout = setTimeout(function () {
          app.fire('frame-3', {
            homeServices: homeServices,
            parts: parts,
            svgId: svgId,
            items: items,
          });
        }, 100);
    }
    window.addEventListener('load', frame3Handler);
    window.addEventListener('scroll', frame3Handler);
  })();

  /*
  HOME SERVICES FRAME (HOME SCREEN 4)
  */
  (function(){
    const casesGallery = document.querySelector('.cases-gallery');
    const casesOverlay = document.querySelector('.cases-overlay');
    if (!casesGallery) return;
    const casesOverlayParts = casesOverlay.querySelectorAll('.cases-overlay__part');
    const casesSvgId = 'cases-logo';
    const cases = document.querySelector('.cases');
    const splitNodes = casesGallery.querySelectorAll('.split-text');
    casesGallery.style.top = $(cases).offset().top + 'px';
    const countCasesTop = () => {
      casesGallery.style.top = $(cases).offset().top + 'px';
    }
    window.addEventListener('load', countCasesTop);
    window.addEventListener('resize', () => {
      setTimeout(()=>{
        countCasesTop();
      }, 3000)
    });
    const gallery = new CasesGallery(casesGallery.querySelector('.cases-gallery__inner'));
    let timeout;
    const frame4Handler = function(){
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if (($(window).scrollTop() >= $(casesOverlay).offset().top - offset) || app.width() <= 1024)
      if (!timeout)
        timeout = setTimeout(function () {
          app.fire('frame-4', {
            casesGallery: casesGallery,
            parts: casesOverlayParts,
            svgId: casesSvgId,
            gallery: gallery,
            splitNodes: splitNodes
          });
        }, 100);
    }
    window.addEventListener('load', frame4Handler);
    window.addEventListener('scroll', frame4Handler);
  })();

  /*
  MISSIONS SLIDER (HOME SCREEN 5)
  HOME MISSION FRAME (SCREEN 5)
  */
  (function(){
    const mission = document.querySelector('.mission');
    if (!mission) return;
    const missionContent = document.querySelector('.mission-images');
    const helper =  mission.querySelector('.mission__helper');
    const button = document.querySelector('.mission__button');
    const splited = document.querySelectorAll('.mission .split-text span, .mission__button .split-text span');
    const slides = {};
    slides.nav = mission.querySelectorAll('.mission__list-item');
    slides.back = missionContent.querySelectorAll('.mission-images__back-slide');
    slides.front = missionContent.querySelectorAll('.mission-images__front-slide');
    slides.text = missionContent.querySelectorAll('.mission-annotation__slide');
    for (let i = 0; i < slides.nav.length; i++) {
      slides.nav[i].addEventListener('click', function(){
        let activeNav = mission.querySelector('.mission__list-item.animated');
        if (activeNav == slides.nav[i]) return;
        if (activeNav) activeNav.classList.remove('animated');
        let actives = missionContent.querySelectorAll('.animated');
        if (actives.length) {
          for (let i = 0; i < actives.length; i++) {
            actives[i].classList.remove('animated');
          }
        }
        slides.nav[i].classList.add('animated');
        slides.back[i].classList.add('animated');
        slides.front[i].classList.add('animated');
        slides.text[i].classList.add('animated');
        if (slides.interval) clearInterval(slides.interval);
        slides.interval = setInterval(function () {
          if (i === slides.nav.length-1) {
            slides.nav[0].click();
          } else {
            slides.nav[i+1].click();
          }
        }, 8000);
      });
    }
    const slidesRender = function(){
      this.back[0].classList.add('animated');
      this.front[0].classList.add('animated');
      this.text[0].classList.add('animated');
      this.nav[0].click();
    }.bind(slides);

    let timeout;
    const frame5Handler = function(){
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(mission).offset().top - offset)
        if (!timeout)
          timeout = setTimeout(function () {
            app.fire('frame-5', {
              mission: mission,
              helper: helper,
              splited: splited,
              slidesRender: slidesRender,
              button: button
            });
          }, 100);
    }
    window.addEventListener('load', frame5Handler);
    window.addEventListener('scroll', frame5Handler);
  })();

  /*
  EMPLOYEES SLIDERS (HOME SCREEN 6)
  HOME EMPLOYEES FRAME (SCREEN 6)
  */
  (function(){
    const employees = document.querySelector('.employees');
    if (!employees) return;
    const employeesOverlay = document.querySelector('.employees-overlay');
    const info = employeesOverlay.querySelector('.employees__info');
    const nameOutput = employeesOverlay.querySelector('.employees__name');
    const descriptionOutput = employeesOverlay.querySelector('.employees__description');
    const border = employeesOverlay.querySelector('.employees__border');
    const data = JSON.parse(document.getElementById('employees-data').innerHTML);
    const rect = employeesOverlay.querySelector('.employees__rect');
    const inscription = document.getElementById('employees-inscription');
    const helper = employeesOverlay.querySelector('.employees__helper');
    const link = employeesOverlay.querySelector('.employees__link');
    const parts = employeesOverlay.querySelectorAll('.employees-overlay__part');
    const output = employeesOverlay.querySelector('.employees__output-slide');
    const leftGallery = employeesOverlay.querySelector('.employee-gallery--right div');
    const rightGallery = employeesOverlay.querySelector('.employee-gallery--left div');
    if (app.width()<=768) {
      $(output).after(info);
    }
    const svgId = 'employees-svg';
    const images = [];
    const names = [];
    const descriptions = [];
    let counter = 0;
    let timeout;
    for (let i = 0; i < data.length; i++) {
      let img = new Image();
      img.src = data[i].img;
      img.setAttribute('data-employee', i);
      img.onload = function(){
        counter++;
        if (counter==data.length) {
          window.addEventListener('load', frame6handler);
          window.addEventListener('scroll', frame6handler);
        }

      }
      images.push(img);
      names.push(data[i].name);
      descriptions.push(data[i].description);
    }
    const frame6handler = function(){
      if (($(window).scrollTop() >= $(employeesOverlay).offset().top-400) || app.width() <= 1024)
        if (!timeout)
          timeout = setTimeout(function () {
            app.fire('frame-6', {
              images: images,
              nameOutput: nameOutput,
              descriptionOutput: descriptionOutput,
              names: names,
              descriptions: descriptions,
              rect: rect,
              output: output,
              leftGallery: leftGallery,
              rightGallery: rightGallery,
              info: info,
              inscription: inscription,
              svgId: svgId,
              border: border,
              helper: helper,
              link: link,
              parts: parts
            });
          }, 100);
    }


  })();


  /*
  HOME ARTICLES FRAME (SCREEN 7)
  */
  (function(){
    const articles = document.querySelector('.articles');
    if (!articles) return;
    const title = articles.querySelector('.articles__title');
    const parts = articles.querySelectorAll('.article, .articles__more');
    const row = articles.querySelector('.articles__row');
    let timeout;
    const frame7Handler = function(){
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(articles).offset().top - offset) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('frame-7', {
              articles: articles,
              title: title,
              parts: parts,
              row: row
            })
          }, 100);
      }
    }
    window.addEventListener('load', frame7Handler);
    window.addEventListener('scroll', frame7Handler);
  })();

  /*
  HOME FOOTER FRAME (SCREEN 8)
  */

  (function(){
    const footer = document.querySelector('.footer');
    if (!footer) return ;
    const footerGrid = document.querySelector('.footer-grid');
    const gridItems = footerGrid.querySelectorAll('.footer-grid__item');
    const parts = footer.querySelectorAll('.footer__part');
    const svgId = 'footer-svg';
    const serifs = footerGrid.querySelectorAll('.serif');
    let timeout;
    const frame8Handler = function(){
      let offset = app.width() > 1366 ? 800 : app.width() == 1280 ? 900 : 400;
      if ($(window).scrollTop() >= $(footer).offset().top - offset)
        if (!timeout)
          timeout = setTimeout(function () {
            app.fire('frame-8', {
              footer: footer,
              gridItems: gridItems,
              parts: parts,
              svgId: svgId,
              serifs: serifs
            });
          }, 100);
    }
    window.addEventListener('load', frame8Handler);
    window.addEventListener('scroll', frame8Handler);
  })();

  /*
  BORDERS
  */
  (function(){
    const borderedElems = document.querySelectorAll('.bordered');
    if (!borderedElems.length) return;
    const html =
      `<div class="border-1"></div>
      <div class="border-2"></div>
      <div class="border-3"></div>
      <div class="border-4"></div>`;
    for (let i = 0; i < borderedElems.length; i++) {
      borderedElems[i].insertAdjacentHTML('beforeend', html);
    }
  })();
});
