/*
COMPLECT PAGE FRAME ANIMATION
*/

const complectFrames = function(window, document, app, TimelineLite, Vivus, Back) {
  app.on('complect-frame-1', ({
    header,
    splitedHeaderText,
    headerParts,
    authorParts,
    splitedText,
    list,
    splitNodes
  }) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .1, "+=0");
        for (let i = 0; i < authorParts.length; i++) {
          authorParts[i].classList.add('animated');
        }
      }, 1200);
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedText, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .01, "+=0");
      }, 2000);
      setTimeout(() => {
        list.classList.add('animated');
        let index = 0;
        const interval = setInterval(() => {
          splitNodes[index].classList.add('animated');
          let splited = splitNodes[index++].querySelectorAll('span');
          let tl = new TimelineLite();
          tl.staggerTo(splited, 1, {
            opacity: 1,
            ease: Back.easeOut
          }, .005, "+=0");
          if (index === splitNodes.length)
            clearInterval(interval);
        }, 250);
      }, 3000);
    });
  });

  /*
  HEADER SCREEN + AUTHOR SCREEN
  */
  (function() {
    const header = document.querySelector('.header--complect');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.split-text span');
    const headerParts = header.querySelectorAll('.header__part');
    const author = document.querySelector('.author');
    const authorParts = author.querySelectorAll('.author__part');
    const splitedText = author.querySelectorAll('.author__text-title span, .author__text-description span');
    const list = author.querySelector('.author__list');
    const splitNodes = list.querySelectorAll('.author__list-item');
    const complectFrame1Handler = () => {
        app.fire('complect-frame-1', {
          header: header,
          splitedHeaderText: splitedHeaderText,
          headerParts: headerParts,
          authorParts: authorParts,
          splitedText: splitedText,
          list: list,
          splitNodes: splitNodes,
        });
      }
      // window.addEventListener('load', complectFrame1Handler);
    complectFrame1Handler();
  })();



}

export default complectFrames;
