class Observer {

  constructor(){    
    this.events = {};
  }

  fire(eventName, args){
    var eventArr = this.events[eventName];
    if (!eventArr) return;
    for (var i = 0; i < eventArr.length; i++) {
      eventArr[i].call(this, args ? args : undefined);
    }
  }

  on(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback.bind(this));
  }

  off(eventName) {
    if (!this.events[eventName]) return;
    this.events[eventName] = [];
  }

}

export default Observer;
