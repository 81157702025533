/*
BLOG INDEX PAGE FRAME ANIMATION
*/

const blogFrames = function(window, document, app, TimelineLite, Vivus, Back) {
  /*
  HEADER SCREEN
  */
  app.on('blog-frame-1', ({
    header,
    splitedHeaderText,
    headerParts
  }) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .1, "+=0");
        app.fire('blog-frame-1-end');
      }, 1200);
    });
  });
  (function() {
    const header = document.querySelector('.header--blog');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.split-text span');
    const headerParts = header.querySelectorAll('.header__part');

    const blogFrame1Handler = () => {
        app.fire('blog-frame-1', {
          header: header,
          splitedHeaderText: splitedHeaderText,
          headerParts: headerParts,
        });
      }
      // window.addEventListener('load', blogFrame1Handler);
    blogFrame1Handler();
  })();


  /*
  BLOG ITEMS FADE EFFECT
  */
  (function() {
    const blog = document.querySelector('.blog');
    if (!blog) return;
    const items = document.querySelectorAll('.blog-item');
    const itemHeight = items[0].offsetHeight;
    const blogButton = document.querySelector('.blog-button');
    const blogButtonWrapper = blogButton.parentElement;

    const blogItemsHandler = function() {
      let scroll = $(window).scrollTop();
      let offset = app.width() > 1366 ? 900 : app.width() <= 1280 ? 900 : 600;
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if ((!item.classList.contains('animated')) && (scroll >= $(item).offset().top - itemHeight - offset)) {
          item.classList.add('animated');
        }
      }
      if (scroll >= $(blogButtonWrapper).offset().top - (offset - 200))
        blogButton.classList.add('animated');
    }
    app.on('blog-frame-1-end', () => {
      blogItemsHandler();
      window.addEventListener('scroll', blogItemsHandler);
    });
  })();
}

export default blogFrames;
