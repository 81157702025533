/*
PARTNERS INDEX PAGE FRAME ANIMATION
*/

const partnersFrames = function(window, document, app, TimelineLite, Vivus, Back){

  app.on('partners-frame-1', ({header, splitedHeaderText, headerParts, svgId, splitNodes}) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      new Vivus(svgId, {duration: 1500, type: 'async', start: 'autostart'});
      setTimeout(()=>{
        let tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
            opacity:1,
            ease:Back.easeOut
          }, .1, "+=0"
        );
        app.fire('partners-frame-1-end');
        let index = 0;
        let interval = setInterval(() => {
          splitNodes[index].classList.add('animated');
          let splited = splitNodes[index++].querySelectorAll('span');
          let tl  = new TimelineLite();
          tl.staggerTo(splited, 2, {
              opacity:1,
              ease:Back.easeOut
            }, .025, "+=0"
          );
          if (index === splitNodes.length)
            clearInterval(interval);
        }, 250);
      }, 1200);
    });
  });
  /*
  HEADER SCREEN
  */
  (function(){
    const header = document.querySelector('.header--partners');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.header__text .split-text span');
    const headerParts = header.querySelectorAll('.header__part');
    const splitNodes = header.querySelectorAll('.header-filters__link');
    const svgId = 'header-svg-logo';

    const partnersFrame1Handler = () => {
      app.fire('partners-frame-1', {
        header: header,
        splitedHeaderText: splitedHeaderText,
        headerParts: headerParts,
        svgId: svgId,
        splitNodes: splitNodes
      });
    }
    // window.addEventListener('load', partnersFrame1Handler);
    partnersFrame1Handler();
  })();


  /*
  PARTNERS ITEMS FADE EFFECT
  */
  (function(){
    const partners = document.querySelector('.partners');
    if (!partners) return;
    const items = partners.querySelectorAll('.partners__item');
    const itemHeight = items[0].offsetHeight;
    const partnersButton = document.querySelector('.blog-button');
    const partnersButtonWrapper = partnersButton.parentElement;

    const partnersItemsHandler = function(){
      let scroll = $(window).scrollTop();
      let offset = app.width() <= 1366 && app.width() > 1280 ? 500 : 900;
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if ( ( !item.classList.contains('animated') ) && ( scroll >= $(item).offset().top - itemHeight - offset) ) {
          item.classList.add('animated');
        }
      }
      if (scroll >= $(partnersButtonWrapper).offset().top - offset - 200)
        partnersButton.classList.add('animated');
    }
    app.on('partners-frame-1-end', () => {
      partnersItemsHandler();
      window.addEventListener('scroll', partnersItemsHandler);
    });
  })();

}

export default partnersFrames;
