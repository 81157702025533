/*
REMONT PAGE FRAME ANIMATION
*/

const remontFrames = function(window, document, app, TimelineLite, Vivus, Back) {

  app.on('remont-frame-1', ({
    header,
    splitedHeaderText,
    headerParts,
    authorParts,
    splitedText,
    list,
    splitNodes
  }) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .1, "+=0");
        for (let i = 0; i < authorParts.length; i++) {
          authorParts[i].classList.add('animated');
        }
      }, 1200);
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedText, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .01, "+=0");
      }, 2000);
      setTimeout(() => {
        list.classList.add('animated');
        let index = 0;
        const interval = setInterval(() => {
          splitNodes[index].classList.add('animated');
          let splited = splitNodes[index++].querySelectorAll('span');
          let tl = new TimelineLite();
          tl.staggerTo(splited, 1, {
            opacity: 1,
            ease: Back.easeOut
          }, .005, "+=0");
          if (index === splitNodes.length)
            clearInterval(interval);
        }, 250);
      }, 3000);
    });
  });
  app.on('remont-frame-2', ({
    parts,
    splited,
    svgId
  }) => {
    for (var i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    const tl = new TimelineLite();
    tl.staggerTo(splited, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .05, "+=0");
    new Vivus(svgId, {
      duration: 1500,
      type: 'async',
      start: 'autostart'
    });
  });

  /*
  HEADER SCREEN + AUTHOR SCREEN
  */
  (function() {
    const header = document.querySelector('.header--remont');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.split-text span');
    const headerParts = header.querySelectorAll('.header__part');
    const author = document.querySelector('.author');
    const authorParts = author.querySelectorAll('.author__part');
    const splitedText = author.querySelectorAll('.author__text-title span, .author__text-description span');
    const list = author.querySelector('.author__list');
    const splitNodes = list.querySelectorAll('.author__list-item');
    const remontFrame1Handler = () => {
        app.fire('remont-frame-1', {
          header: header,
          splitedHeaderText: splitedHeaderText,
          headerParts: headerParts,
          authorParts: authorParts,
          splitedText: splitedText,
          list: list,
          splitNodes: splitNodes,
        });
      }
      // window.addEventListener('load', remontFrame1Handler);
    remontFrame1Handler();
  })();


  /*
  FORM FRAME
  */
  (function() {
    const form = document.querySelector('.remont-form');
    if (!form) return;
    const parts = form.querySelectorAll('.remont-form__part, .remont-form__input-wrapper');
    const splited = form.querySelectorAll('.split-text span');
    const svgId = 'remont-form-svg-logo';
    let timeout;
    const remontFrame2Handler = () => {
      if ($(window).scrollTop() >= $(form).offset().top - 600)
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('remont-frame-2', {
              parts: parts,
              splited: splited,
              svgId: svgId
            });
          }, 100);
    };
    window.addEventListener('load', remontFrame2Handler);
    window.addEventListener('scroll', remontFrame2Handler);
  })();


}

export default remontFrames;
