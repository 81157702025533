/*
CONTACTS PAGE FRAME ANIMATION
*/
import ContactsMap from './contacts-map';

const contactsFrames = function(window, document, app, TimelineLite, Vivus, Back){
  app.on('contacts-frame-1', ({header, splitedHeaderText, headerParts}) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(()=>{
        let tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
            opacity:1,
            ease:Back.easeOut
          }, .1, "+=0"
        );
        app.fire('contacts-frame-1-end');
      }, 1200);
    });
  });
  /*
  HEADER SCREEN
  */
  (function(){
    const header = document.querySelector('.header--contacts');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.header__text .split-text span');
    const headerParts = header.querySelectorAll('.header__part');

    const contactsFrame1Handler = () => {
      app.fire('contacts-frame-1', {
        header: header,
        splitedHeaderText: splitedHeaderText,
        headerParts: headerParts,
      });
    }
    window.addEventListener('load', contactsFrame1Handler);
    contactsFrame1Handler();
  })();


  (function(){
    const frame = document.querySelector('.contacts');
    if (!frame) return;
    const parts = frame.querySelectorAll('.contacts__part');
    const splited = frame.querySelectorAll('.contacts-text .split-text span, .contacts-text__icon-route');
    const formParts = frame.querySelectorAll('.contacts-form__title, .contacts-form__input-wrapper, .contacts-form__button-wrapper');
    const svgId = 'contacts-svg';
    const bg = document.querySelector('.contacts-bg');
    const bgSvgWrapper = document.querySelector('.contacts-bg__svg-wrapper');
    const bgSvgId = 'contacts-bg-svg';

    app.on('contacts-frame-1-end', () => {
      for (let i = 0; i < parts.length; i++) {
        parts[i].classList.add('animated');
      }
      new Vivus(svgId, {duration: 250, type: 'async', start: 'autostart'});
      setTimeout(()=>{
        const tl = new TimelineLite();
        tl.staggerTo(splited, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .025, '+=0');
      }, 350);
      setTimeout(()=>{
        let index = 0;
        let interval = setInterval(() => {
          formParts[index++].classList.add('animated');
          if (index === formParts.length) {
            clearInterval(interval);
            bg.classList.add('animated');
            setTimeout(()=>{
              bgSvgWrapper.classList.add('animated');
              new Vivus(bgSvgId, {duration: 1000, type: 'async', start: 'autostart'});
            }, 1000);
          }
        }, 250);
      }, 1000);
    });
  })();

  (function(){
    const node = document.getElementById('map');
    if (!node) return;
    const map = new ContactsMap(node, {
      center: {
        lat:55.714871,
        lng:37.696635
      },
      zoom: 14,
      scrollwheel: false
    });
    map.init();
  })();
}

export default contactsFrames;
