/*
COMPLECT PAGE FRAME ANIMATION
*/

const complexFrames = function(window, document, app, TimelineLite, Vivus, Back){

  app.on('complex-frame-1', ({header, splitedHeaderText, headerParts, authorParts, splitedText, list, splitNodes}) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(()=>{
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
            opacity:1,
            ease:Back.easeOut
          }, .1, "+=0"
        );
        for (let i = 0; i < authorParts.length; i++) {
          authorParts[i].classList.add('animated');
        }
      }, 1200);
      setTimeout(()=>{
        const tl = new TimelineLite();
        tl.staggerTo(splitedText, 1, {
            opacity:1,
            ease:Back.easeOut
          }, .01, "+=0"
        );
      }, 2000);
      setTimeout(()=>{
        list.classList.add('animated');
        let index = 0;
        const interval = setInterval(()=>{
          splitNodes[index].classList.add('animated');
          let splited = splitNodes[index++].querySelectorAll('span');
          let tl = new TimelineLite();
          tl.staggerTo(splited, 1, {
              opacity:1,
              ease:Back.easeOut
            }, .005, "+=0"
          );
          if (index === splitNodes.length)
            clearInterval(interval);
        }, 250);
      }, 3000);
    });
  });
app.on('complex-frame-2', ({video, parts, helper, bg, svgId, svgWrapper}) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    setTimeout( () => {
      new Vivus(svgId, {duration: 1500, type: 'async', start: 'autostart'});
      svgWrapper.classList.add('animated');
      bg.classList.add('animated');
    }, 1000);
    setTimeout( () => {
      helper.classList.add('animated');
    }, 2000);
  });
app.on('complex-frame-3', ({frame, parts, svgId, splitedHeaderText, inners, splitTitles, comparisons, buttons, lists}) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    new Vivus(svgId, {duration: 1500, type: 'async', start: 'autostart'});
    let tl = new TimelineLite();
    tl.staggerTo(splitedHeaderText, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .1, "+=0");
    setTimeout(()=>{
      for (let i = 0; i < inners.length; i++) {
        inners[i].classList.add('animated');
      }
      for (let i = 0; i < splitTitles.length; i++) {
        let splited = splitTitles[i].querySelectorAll('span');
        let tl = new TimelineLite();
        tl.staggerTo(splited, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .045, '+=0');
      }
      setTimeout(()=>{
        for (let i = 0; i < comparisons.length; i++) {
          comparisons[i].classList.add('animated');
        }
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].classList.add('animated');
        }
        for (let i = 0; i < lists.length; i++) {
          let lis = lists[i].querySelectorAll('li');
          let length = lis.length;
          let index = 0;
          let interval = setInterval(()=>{
            let splited = lis[index++].querySelectorAll('span');
            let tl = new TimelineLite();
            tl.staggerTo(splited, 1, {
              opacity: 1,
              ease: Back.easeOut
            }, .01, '+=0');
            if (index == length)
              clearInterval(interval);
          }, 250);
        }
      }, 500);
    }, 1000);
  });
  /*
  HEADER SCREEN + AUTHOR SCREEN
  */
  (function(){
    const header = document.querySelector('.header--complex');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.split-text span');
    const headerParts = header.querySelectorAll('.header__part');
    const author = document.querySelector('.author');
    const authorParts =  author.querySelectorAll('.author__part');
    const splitedText = author.querySelectorAll('.author__text-title span, .author__text-description span');
    const list = author.querySelector('.author__list');
    const splitNodes = list.querySelectorAll('.author__list-item');
    const complexFrame1Handler = () => {
      app.fire('complex-frame-1', {
        header: header,
        splitedHeaderText: splitedHeaderText,
        headerParts: headerParts,
        authorParts: authorParts,
        splitedText: splitedText,
        list: list,
        splitNodes: splitNodes,
      });
    }
    // window.addEventListener('load', complexFrame1Handler);
    complexFrame1Handler();
  })();


  /*
  VIDEO SCREEN + ABOUT SCREEN
  */
  (function(){
    const video = document.querySelector('div.video--complex');
    if (!video) return;
    const parts = video.querySelectorAll('.video__part');
    const helper = video.querySelector('.video__helper');
    const bg = video.querySelector('.video__bg');
    const svgWrapper = video.querySelector('.video__svg-wrapper');
    const svgId = 'video-svg';
    let timeout;
    const complexFrame2Handler = () => {
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(video).offset().top - offset) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('complex-frame-2', {
              video: video,
              parts: parts,
              helper: helper,
              bg: bg,
              svgId: svgId,
              svgWrapper: svgWrapper
            });
          }, 100);
      }
    }
    window.addEventListener('load', complexFrame2Handler);
    window.addEventListener('scroll', complexFrame2Handler);
  })();



  /*
  VIDEO SCREEN + ABOUT SCREEN
  */
  (function(){
    const frame = document.querySelector('.complex-cost');
    if (!frame) return;
    const parts = frame.querySelectorAll('.complex-cost__part');
    const svgId = 'complex-cost-svg-logo';
    const splitedHeaderText = frame.querySelectorAll('.complex-cost__titles .split-text span');
    const inners = frame.querySelectorAll('.cost-content__inner');
    const splitTitles = frame.querySelectorAll('.cost-content__description, .cost-content__title');
    const lists = frame.querySelectorAll('.cost-content__list');
    const comparisons = frame.querySelectorAll('.cost-content__comparison');
    const buttons = frame.querySelectorAll('.cost-content__button');

    let timeout;
    const complexFrame3Handler = () => {
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(frame).offset().top - offset) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('complex-frame-3', {
              frame: frame,
              parts: parts,
              svgId: svgId,
              splitedHeaderText: splitedHeaderText,
              inners: inners,
              splitTitles: splitTitles,
              comparisons: comparisons,
              buttons: buttons,
              lists: lists
            });
          }, 100);
      }
    }
    window.addEventListener('load', complexFrame3Handler);
    window.addEventListener('scroll', complexFrame3Handler);
  })();


}

export default complexFrames;
