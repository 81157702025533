const crEl = document.createElement.bind(document);
class CasesGallery {
  constructor(node) {
    this.node = node;
    this.slides = node.querySelectorAll('.slide');
    this.controls = crEl('ul');
    this.lis = [];
    this.controls.classList.add('cases-gallery__dots');
    this.arrowPrev = node.querySelector('.cases-gallery__arrow--prev');
    this.arrowNext = node.querySelector('.cases-gallery__arrow--next');
    for (let i = 0; i < this.slides.length; i++) {
      let li = crEl('li');
      li.classList.add('cases-gallery__dot');
      this.controls.appendChild(li);
      this.lis.push(li);
      li.addEventListener('click', ()=>{
        let active = node.querySelectorAll('.active');
        if (active.length) {
          for (let i = 0; i < active.length; i++) {
            active[i].classList.remove('active');
          }
        }
        li.classList.add('active');
        this.slides[i].classList.add('active');
      });
    }
    this.arrowNext.addEventListener('click', ()=>{
      let activeSlide = node.querySelector('.slide.active');
      let activeLi = this.controls.querySelector('.active');
      if (activeSlide)
        activeSlide.classList.remove('active');
      if (activeLi)
        activeLi.classList.remove('active');
      for (var i = 0; i < this.slides.length; i++) {
        if (this.slides[i] === activeSlide) {
          if (i==this.slides.length-1) {
            this.slides[0].classList.add('active');
            this.lis[0].classList.add('active');
          }
          else {
            this.slides[i+1].classList.add('active');
            this.lis[i+1].classList.add('active');
          }
        }
      }
    });
    this.arrowPrev.addEventListener('click', ()=>{
      let activeSlide = node.querySelector('.slide.active');
      let activeLi = this.controls.querySelector('.active');
      if (activeSlide)
        activeSlide.classList.remove('active');
      if (activeLi)
        activeLi.classList.remove('active');
      for (var i = 0; i < this.slides.length; i++) {
        if (this.slides[i] === activeSlide) {
          if (i==0) {
            this.slides[this.slides.length-1].classList.add('active');
            this.lis[this.slides.length-1].classList.add('active');
          } else {
            this.slides[i-1].classList.add('active');
            this.lis[i-1].classList.add('active');            
          }
        }
      }
    });
  }
  render(){
    this.node.appendChild(this.controls);
    this.slides[0].classList.add('active');
    this.controls.querySelector('li').classList.add('active');
  }
}

export default CasesGallery;
