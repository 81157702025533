/*
portfolio INDEX PAGE FRAME ANIMATION
*/

const portfolioFrames = function(window, document, app, TimelineLite, Vivus, Back) {

  app.on('portfolio-frame-1', ({
    header,
    splitedHeaderText,
    headerParts,
    svgId
  }) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      new Vivus(svgId, {
        duration: 1500,
        type: 'async',
        start: 'autostart'
      });
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .1, "+=0");
        app.fire('portfolio-frame-1-end');
      }, 1200);
    });
  });

  /*
  HEADER SCREEN
  */
  (function() {
    const header = document.querySelector('.header--portfolio');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.split-text span');
    const headerParts = header.querySelectorAll('.header__part');
    const svgId = 'header-svg-logo';

    const portfolioFrame1Handler = () => {
        app.fire('portfolio-frame-1', {
          header: header,
          splitedHeaderText: splitedHeaderText,
          headerParts: headerParts,
          svgId: svgId
        });
      }
      // window.addEventListener('load', portfolioFrame1Handler);
    portfolioFrame1Handler();
  })();


  /*
  PORTFOLIO ITEMS FADE EFFECT
  */
  (function() {
    const portfolio = document.querySelector('.portfolio');
    if (!portfolio) return;
    const items = document.querySelectorAll('.portfolio__item');
    const itemHeight = items[0].offsetHeight;
    const portfolioButton = document.querySelector('.blog-button');
    const portfolioButtonWrapper = portfolioButton.parentElement;

    const portfolioItemsHandler = function() {
      let offset = app.width() <= 1366 && app.width() > 1280 ? 600 : 900;
      let scroll = $(window).scrollTop();
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if ((!item.classList.contains('animated')) && (scroll >= $(item).offset().top - itemHeight - offset)) {
          item.classList.add('animated');
        }
      }
      if (scroll >= $(portfolioButtonWrapper).offset().top - (offset - 200))
        portfolioButton.classList.add('animated');
    }
    app.on('portfolio-frame-1-end', () => {
      portfolioItemsHandler();
      window.addEventListener('scroll', portfolioItemsHandler);
    });
  })();

}

export default portfolioFrames;
