/*
AUTHOR PAGE FRAME ANIMATION
*/

const authorFrames = function(window, document, app, TimelineLite, Vivus, Back) {
  app.on('author-frame-1', ({
    header,
    splitedHeaderText,
    headerParts,
    authorParts,
    splitedText,
    list,
    splitNodes
  }) => {
    app.on('frame-0-end', () => {
      for (let i = 0; i < headerParts.length; i++) {
        headerParts[i].classList.add('animated');
      };
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedHeaderText, 2, {
          opacity: 1,
          ease: Back.easeOut
        }, .1, "+=0");
        for (let i = 0; i < authorParts.length; i++) {
          authorParts[i].classList.add('animated');
        }
      }, 1200);
      setTimeout(() => {
        const tl = new TimelineLite();
        tl.staggerTo(splitedText, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, .01, "+=0");
      }, 2000);
      setTimeout(() => {
        list.classList.add('animated');
        let index = 0;
        const interval = setInterval(() => {
          splitNodes[index].classList.add('animated');
          let splited = splitNodes[index++].querySelectorAll('span');
          let tl = new TimelineLite();
          tl.staggerTo(splited, 1, {
            opacity: 1,
            ease: Back.easeOut
          }, .005, "+=0");
          if (index === splitNodes.length)
            clearInterval(interval);
        }, 250);
      }, 3000);
    });
  });

  /*
  SCHEME SCREEN AND GALLERY
  */
  (function() {
    const scheme = document.querySelector('.scheme');
    if (!scheme) return;
    const parts = scheme.querySelectorAll('.scheme__part');
    const splited = scheme.querySelectorAll('.split-text span');
    const trigger = scheme.querySelector('.scheme-trigger');
    const svgLeftId = 'author-logo-left';
    const svgRightId = 'author-logo-right';
    let timeout;
    const authorFrame2Handler = () => {
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : app.width() <= 768 ? 1000 : 400;
      if ($(window).scrollTop() >= $(scheme).offset().top - offset)
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('author-frame-2', {
              parts: parts,
              splited: splited,
              trigger: trigger,
              svgLeftId: svgLeftId,
              svgRightId: svgRightId,
            })
          }, 100);
    }
    window.addEventListener('load', authorFrame2Handler);
    window.addEventListener('scroll', authorFrame2Handler);
  })();
  app.on('author-frame-2', ({
    parts,
    splited,
    trigger,
    svgLeftId,
    svgRightId
  }) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    new Vivus(svgLeftId, {
      duration: 3000,
      type: 'async',
      start: 'autostart'
    });
    if (document.getElementById(svgRightId))
      new Vivus(svgRightId, {
        duration: 1500,
        type: 'async',
        start: 'autostart'
      });
    trigger.classList.add('animated');
    const tl = new TimelineLite();
    tl.staggerTo(splited, 2, {
      opacity: 1,
      ease: Back.easeOut
    }, .1, "+=0");
    app.fire('author-frame-2-ready');
  });
  app.on('author-frame-2-ready', () => {
    const crEl = document.createElement.bind(document);
    const scheme = document.querySelector('.scheme');
    if (!scheme) return;
    const data = JSON.parse(document.getElementById('author-scheme-data').innerHTML);
    const imageWrappers = scheme.querySelectorAll('.scheme-block__image-wrapper');
    const number = scheme.querySelector('.scheme-trigger__number');
    const buttonUp = scheme.querySelector('.scheme-trigger__button--up');
    const buttonDown = scheme.querySelector('.scheme-trigger__button--down');
    const outputText = scheme.querySelector('.scheme-block__text');
    const outputTitle = scheme.querySelector('.scheme-block__title');
    const outputDescription = scheme.querySelector('.scheme-block__description');
    const list = scheme.querySelector('.scheme-list__list');
    const listTop = list.offsetTop;
    const waypoints = [];
    var current = 0;
    let timeout, interval, bigTimeout;
    let delay = app.width() < 640 ? 100 : 700;
    const navTo = function({
      index,
      li,
      direction = null
    }) {
      return function() {
        if (interval) {
          clearInterval(interval);
          initInterval();
        }
        if (bigTimeout) {
          clearTimeout(bigTimeout);
        }
        if (direction) {
          switch (direction) {
            case 'next':
              index = current == data.length - 1 ? data.length - 1 : current + 1;
              break;
            case 'prev':
              index = current == 0 ? 0 : current - 1;
              break;
            default:
              break;
          }
        }
        if (index >= data.length || index < 0) return;
        if (current === index) return;
        const active = list.querySelector('.active');
        if (active)
          active.classList.remove('active');
        list.style.top = `${listTop - waypoints[index]}px`;
        number.innerHTML = index + 1;
        current = index;
        if (li) {
          li.classList.add('active');
        } else {
          list.querySelectorAll('li')[index].classList.add('active');
        }
        outputText.classList.remove('animated');
        for (let i = 0; i < imageWrappers.length; i++) {
          imageWrappers[i].classList.remove('animated');
        }
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          outputTitle.innerHTML = data[index].title;
          outputDescription.innerHTML = data[index].description;
          outputText.classList.add('animated');
          for (let i = 0; i < imageWrappers.length; i++) {
            imageWrappers[i].style.backgroundImage = `url(${data[index].images[i]})`;
            imageWrappers[i].classList.add('animated');
          }
        }, delay);
      }
    }
    for (let i = 0; i < data.length; i++) {
      let li = crEl('li');
      li.className = 'scheme-list__item';
      if (i == 0) {
        li.classList.add('active');
        outputText.classList.add('animated');
        outputTitle.innerHTML = data[i].title;
        outputDescription.innerHTML = data[i].description;
        for (let j = 0; j < imageWrappers.length; j++) {
          imageWrappers[j].style.backgroundImage = `url(${data[i].images[j]})`;
          imageWrappers[j].classList.add('animated');
        }
      }
      li.textContent = data[i].title;
      list.appendChild(li);
      li.addEventListener('click', navTo({
        index: i,
        li: li
      }));
      waypoints.push(li.offsetTop);
    }
    buttonUp.addEventListener('click', navTo({
      direction: 'prev'
    }));
    buttonDown.addEventListener('click', navTo({
      direction: 'next'
    }));
    const initInterval = function() {
      interval = setInterval(() => {
        buttonDown.click();
        if (current === data.length - 1) {
          clearInterval(interval);
          initBigTimeout();
        }
      }, 10000);
    }
    const initBigTimeout = function() {
      bigTimeout = setTimeout(() => {
        navTo({
          index: 0
        })();
      }, 20000);
    }
    initInterval();
  });
  app.on('author-frame-3', ({
    parts,
    splitNodes,
    svgId,
    title,
    advantagesArr,
    advantagesDescrArr
  }) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    new Vivus(svgId, {
      duration: 1000,
      type: 'async',
      start: 'autostart'
    });
    const titleTl = new TimelineLite();
    titleTl.staggerTo(title, 2, {
      opacity: 1,
      ease: Back.easeOut
    }, .05, "+=0");
    for (let i = 0; i < advantagesArr.length; i++) {
      advantagesArr[i].classList.add('animated');
    }
    const speed = advantagesDescrArr.length ? .01 : .025;
    setTimeout(() => {
      let interval;
      let i = 0;
      interval = setInterval(() => {
        let tl = new TimelineLite();
        let splited = splitNodes[i++].querySelectorAll('.split-text span');
        tl.staggerTo(splited, 1, {
          opacity: 1,
          ease: Back.easeOut
        }, speed, "+=0");
        if (i === splitNodes.length)
          clearInterval(interval);
      }, 500);
    }, 1000);
  });
  app.on('author-frame-4', ({
    parts,
    contentBorder,
    content,
    mainTitle,
    splitNodes,
    price,
    button
  }) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    price.classList.add('animated');
    button.classList.add('animated');
    let tl = new TimelineLite();
    setTimeout(() => {
      contentBorder.classList.add('animated');
      setTimeout(() => {
        content.classList.add('animated');
        for (let i = 0; i < splitNodes.length; i++) {
          let splited = splitNodes[i].querySelectorAll('span');
          let tl = new TimelineLite();
          tl.staggerTo(splited, 1, {
            opacity: 1,
            ease: Back.easeOut
          }, .025, "+=0");
        }
      }, 400);
      tl.staggerTo(mainTitle, 1, {
        opacity: 1,
        ease: Back.easeOut
      }, .1, "+=0");
    }, 800);
  });
  app.on('author-frame-5', ({
    parts,
    splited,
    inputs,
    serif
  }) => {
    for (let i = 0; i < parts.length; i++) {
      parts[i].classList.add('animated');
    }
    setTimeout(() => {
      let tl = new TimelineLite();
      tl.staggerTo(splited, 1, {
        opacity: 1,
        ease: Back.easeOut
      }, .05, '+=0');
      setTimeout(() => {
        let interval;
        let index = 0;
        interval = setInterval(() => {
          inputs[index++].classList.add('animated');
          if (index === inputs.length) {
            clearInterval(interval);
            serif.classList.add('animated');
          }
        }, 200);
      }, 500);
    }, 1000);
  });

  app.on('author-frame-6', ({
    bordered,
    parts,
    splited,
    splitedTitle
  }) => {
    bordered.classList.add('animated');
    let index = 0;
    parts[index++].classList.add('animated');
    let interval = setInterval(() => {
      parts[index++].classList.add('animated');
      if (index == parts.length)
        clearInterval(interval);
    }, 300);
    const tl = new TimelineLite();
    tl.staggerTo(splitedTitle, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .05, '+=0');
    const tl1 = new TimelineLite();
    tl1.staggerTo(splited, 1, {
      opacity: 1,
      ease: Back.easeOut
    }, .025, '+=0');
  });
  /*
  HEADER SCREEN + AUTHOR SCREEN
  */
  (function() {
    const header = document.querySelector('.header--author');
    if (!header) return;
    const splitedHeaderText = header.querySelectorAll('.split-text span');
    const headerParts = header.querySelectorAll('.header__part');
    const author = document.querySelector('.author');
    const authorParts = author.querySelectorAll('.author__part');
    const splitedText = author.querySelectorAll('.author__text-title span, .author__text-description span');
    const list = author.querySelector('.author__list');
    const splitNodes = list.querySelectorAll('.author__list-item');
    const authorFrame1Handler = () => {
        app.fire('author-frame-1', {
          header: header,
          splitedHeaderText: splitedHeaderText,
          headerParts: headerParts,
          authorParts: authorParts,
          splitedText: splitedText,
          list: list,
          splitNodes: splitNodes,
        });
      }
      // window.addEventListener('load', authorFrame1Handler);
    authorFrame1Handler();
  })();


  /*
  ADVANTAGES FRAME
  */
  (function() {
    const advantages = document.querySelector('.advantages');
    if (!advantages) return;
    const parts = advantages.querySelectorAll('.advantages__part');
    const text = advantages.querySelector('.advantages__text');
    if (app.width() <= 1024)
      $(parts[1]).prepend(text);
    const title = advantages.querySelectorAll('.advantages__text span');
    const splitNodes = advantages.querySelectorAll('.advantage__sub');
    const advantagesArr = advantages.querySelectorAll('.advantage');
    const advantagesDescrArr = advantages.querySelectorAll('.advantage__description');
    const svgId = 'advantages-svg-logo';
    let timeout;
    const authorFrame3Handler = () => {
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(advantages).offset().top - offset)
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('author-frame-3', {
              parts: parts,
              splitNodes: splitNodes,
              title: title,
              svgId: svgId,
              advantagesArr: advantagesArr,
              advantagesDescrArr: advantagesDescrArr
            })
          }, 100);
    }
    window.addEventListener('load', authorFrame3Handler);
    window.addEventListener('scroll', authorFrame3Handler);
  })();


  /*
  COST FRAME
  */
  (function() {
    const frame = document.querySelector('.cost');
    if (!frame) return;
    const parts = frame.querySelectorAll('.cost__part');
    const contentBorder = frame.querySelector('.cost-content__border');
    const content = frame.querySelector('.cost-content__inner');
    const mainTitle = frame.querySelectorAll('.cost__titles .split-text span');
    const splitNodes = frame.querySelectorAll('.cost-content__text .split-text');
    const price = frame.querySelector('.cost-content__price-wrapper');
    const button = frame.querySelector('.cost-content__button');
    window.addEventListener('load', () => {
      contentBorder.style.height = `${content.offsetHeight}px`;
    });

    let timeout;
    const authorFrame4Handler = function() {
      if ($(window).scrollTop() >= $(frame).offset().top - 1000) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('author-frame-4', {
              parts: parts,
              contentBorder: contentBorder,
              content: content,
              mainTitle: mainTitle,
              splitNodes: splitNodes,
              price: price,
              button: button
            });
          }, 100);
      }
    }
    window.addEventListener('load', authorFrame4Handler);
    window.addEventListener('scroll', authorFrame4Handler);
  })();


  /*
  REQUEST FRAME
  */
  (function() {
    const frame = document.querySelector('.author-request');
    if (!frame) return;
    const parts = frame.querySelectorAll('.author-request__part');
    const splited = frame.querySelectorAll('.split-text span');
    const inputs = frame.querySelectorAll('.author-request__input-wrapper, .author-request__button-wrapper');
    const serif = frame.querySelector('.serif');

    let timeout;
    const authorFrame5Handler = function() {
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(frame).offset().top - offset) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('author-frame-5', {
              parts: parts,
              splited: splited,
              inputs: inputs,
              serif: serif
            });
          }, 100);
      }
    }
    window.addEventListener('load', authorFrame5Handler);
    window.addEventListener('scroll', authorFrame5Handler);
  })();

  /*
  SERVICES FRAME
  */
  (function() {
    const frame = document.querySelector('.author-services');
    if (!frame) return;
    const bordered = frame.querySelector('.author-services__inner');
    const parts = frame.querySelectorAll('.author-services__main, .author-services__item');
    const splitedTitle = frame.querySelectorAll('.author-services__main-title span');
    const splited = frame.querySelectorAll('.author-services__main-description span');

    let timeout;
    const authorFrame6Handler = function() {
      let offset = app.width() > 1366 ? 600 : app.width() == 1280 ? 800 : 400;
      if ($(window).scrollTop() >= $(frame).offset().top - offset) {
        if (!timeout)
          timeout = setTimeout(() => {
            app.fire('author-frame-6', {
              bordered: bordered,
              parts: parts,
              splited: splited,
              splitedTitle: splitedTitle
            });
          }, 100);
      }
    }
    window.addEventListener('load', authorFrame6Handler);
    window.addEventListener('scroll', authorFrame6Handler);
  })();

}

export default authorFrames;
