class SplitText {
  constructor(node) {
    const crEl = document.createElement.bind(document);
    const text = node.innerHTML;
    const length = text.length;
    const space = node.getAttribute('data-split') ? ' ' : '&nbsp;';
    const arr = [];
    for (let i = 0; i < length; i++) {
      let span = crEl('span');
      if (text[i]===' ')
        span.innerHTML = space;
      else
        span.innerHTML = text[i];
      arr.push(span);
    }
    node.innerHTML = '';
    for (let i = 0; i < arr.length; i++) {
      node.appendChild(arr[i]);
    }
    return arr;
  }
}

export default SplitText;
